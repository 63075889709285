import {getBearerToken} from "../service/LoadStorageData";
import React, {useEffect, useState} from "react";

export const LoadAndShowImage = ({url}: { url: string }) => {
    const [imageSrc, setImageSrc] = useState<string | undefined>();

    useEffect(() => {
        const fetchImage = async () => {
            let token = getBearerToken();
            try {
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch image');
                }

                const blob = await response.blob();
                const objectURL = URL.createObjectURL(blob);
                setImageSrc(objectURL);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage().then();
    }, [url]);

    return (
        <div>
            {imageSrc ? (
                <img src={imageSrc} alt="Secured content"/>
            ) : (
                <p>Loading image...</p>
            )}
        </div>
    );
}