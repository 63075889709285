import {FangkarteListItem} from "../models/FangkarteListItem";
import {ExportToCsv} from "export-to-csv";
import TimestampFormat from "../utils/TimestampFormat";
import React from "react";
import {Button, Dropdown} from "react-bootstrap";
import {DownloadExcelCsvButton} from "./DownloadExcelCsvButton";

const listGroupItemNormal = "list-group-item cursor-pointer";
//const listGroupItemActive = "list-group-item cursor-pointer active";

export default function FangkarteList({
                                          listItems,
                                          title,
                                          onItemClicked,
                                          onItemAdd,
                                          onSwitchChanged,
                                          exportListEnabled = false,
                                          addNewElement = false,
                                          deleteElement = false,
                                          showSwitch = false,
                                          updateElement = false,
                                          onItemDelete,
                                          onItemUpdate,
                                          customDownloadLink,
                                      }: {
    listItems: FangkarteListItem[];
    title: string | undefined;
    onItemClicked?: (any: any) => void;
    onItemDelete?: (any: any) => void;
    onItemAdd?: (any: any) => void;
    onItemUpdate?: (any: any) => void;
    onSwitchChanged?: (any: any) => void;
    exportListEnabled?: boolean;
    addNewElement?: boolean;
    deleteElement?: boolean;
    updateElement?: boolean;
    showSwitch?: boolean;
    customDownloadLink?: string | undefined;
}) {
    const createList = () => {
        return listItems.map((item) => {
            return (
                <div className="list-group list-group-flush list-group-hoverable">
                    {createListElement(item)}
                </div>
            );
        });
    };

    const createListElement = (listItem: FangkarteListItem) => {
        return (
            <div
                className={listGroupItemNormal}
                onClick={() =>
                    // TODO (philo.koenneker 03.01.2024) erweitern um "id" und "selected" felder im ListItem
                    onItemClicked ? onItemClicked(listItem.originalElement) : ""
                }
            >
                <div className="row divider">
                    <div className="col-auto">
                        {deleteElement && !updateElement ? showDeleteIcon(listItem) : ""}
                        {!deleteElement && updateElement ? showUpdateIcon(listItem) : ""}
                        {deleteElement && updateElement
                            ? showUpdateDeleteDropdown(listItem)
                            : ""}
                    </div>
                    <div className="col-auto">
                        <div className="col text-truncate">
                            {listItem.title}
                            <div className="d-block text-muted text-truncate mt-n1">
                                {listItem.subTitle}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const showUpdateDeleteDropdown = (listItem: FangkarteListItem) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                    ...
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() =>
                            onItemUpdate ? onItemUpdate(listItem.originalElement) : ""
                        }
                    >
                        Bearbeiten
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() =>
                            onItemDelete ? onItemDelete(listItem.originalElement) : ""
                        }
                    >
                        Löschen
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const showDeleteIcon = (listItem: FangkarteListItem) => {
        return (
            <Button
                onClick={() =>
                    onItemDelete ? onItemDelete(listItem.originalElement) : ""
                }
                variant="danger"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-trash"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 7l16 0"></path>
                    <path d="M10 11l0 6"></path>
                    <path d="M14 11l0 6"></path>
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
            </Button>
        );
    };

    const showUpdateIcon = (listItem: FangkarteListItem) => {
        return (
            <Button
                onClick={() =>
                    onItemUpdate ? onItemUpdate(listItem.originalElement) : ""
                }
                variant="danger"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-trash"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 7l16 0"></path>
                    <path d="M10 11l0 6"></path>
                    <path d="M14 11l0 6"></path>
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
            </Button>
        );
    };

    const downloadCSV = () => {
        if (exportListEnabled) {
            const data = listItems.map((elem) => {
                return elem.originalElement;
            });

            data.forEach((elem) => {
                const keys = Object.keys(elem);
                keys.forEach((key) => {
                    if (!isNaN(elem[key]) && elem[key] > 1000000000) {
                        try {
                            elem[key] = TimestampFormat.toDateOnlyReadableFormat(elem[key]);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                });
            });

            const options = {
                fieldSeparator: ";",
                quoteStrings: '"',
                decimalSeparator: "",
                showLabels: false,
                showTitle: false,
                title: "Export",
                useTextFile: false,
                useBom: false,
                useKeysAsHeaders: false,
            };

            const csvExporter = new ExportToCsv(options);

            csvExporter.generateCsv(data);

            console.log(JSON.stringify(data));
        }
    };

    const createAddItemAction = () => {
        return (
            <div className="card-actions">
                <Button variant="primary" onClick={onItemAdd}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-plus"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 5l0 14"></path>
                        <path d="M5 12l14 0"></path>
                    </svg>
                    Neuer Eintrag
                </Button>
            </div>
        );
    };

    const createDownloadAction = () => {
        return (
            <div className="card-actions">
                <button className="btn" onClick={downloadCSV}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-download"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                        <path d="M7 11l5 5l5 -5"></path>
                        <path d="M12 4l0 12"></path>
                    </svg>
                    Download
                </button>
            </div>
        );
    };

    const createDownloadActionForCustomDownload = () => {
        return (
            <div className="card-actions">
                <DownloadExcelCsvButton
                    downloadUrl={`${customDownloadLink}`}/>
            </div>
        );
    };

    const createSwitchElement = () => {
        return (
            <label className="form-check form-switch">
                <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={onSwitchChanged}
                />
                <span className="form-check-label">Zurückliegende</span>
            </label>
        );
    };

    return (
        <div className="card">
            <div className="card-header">
                <div>
                    <h3 className="card-title">{title}</h3>
                    {showSwitch ? createSwitchElement() : ""}
                </div>
                {exportListEnabled ? createDownloadAction() : ""}
                {customDownloadLink ? createDownloadActionForCustomDownload() : ""}
                {addNewElement ? createAddItemAction() : ""}
            </div>
            <div className="card-body">{createList()}</div>
        </div>
    );
}
