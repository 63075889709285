import { WaterAggregationDTO } from "./model/WaterAggregationDTO";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useEffect, useState } from "react";

export const AmountLengthChart = ({
  aggregation,
}: {
  aggregation: WaterAggregationDTO;
}) => {
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    const data = [];

    for (const fishAggregation of aggregation.fishAggregations) {
      data.push({
        count: fishAggregation.count,
        length: fishAggregation.fishLength,
      });
    }

    setChartData(data);
  }, [aggregation]);

  return (
    <div className="card">
      <div className="card-body">
        {chartData.length === 0 ? (
          "Keine Daten vorhanden"
        ) : (
          <>
            <h5 className="card-title">
              {aggregation.waterName} |{" "}
              {aggregation.fishAggregations[0].fishName} (
              {aggregation.fishAggregations.reduce(
                (sum, entry) => sum + entry.count,
                0, // Initial value for sum
              )}
              )
            </h5>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="length" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="count"
                  fill="#8884d8"
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </div>
  );
};
