import { useEffect, useState } from "react";
import "../js/sidebars.js";
import "bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { getData, storeData } from "../utils/storage/Storage";
import { StorageKey } from "../utils/storage/Keys";
import { OrgaAndRoles } from "../models/OrgaAndRoles";
import { MyOrganisation } from "../dtos/MyOrganisation";
import { NetworkCalls } from "../NetworkCalls";
import { Features } from "../dtos/Features";

export default function Overview() {
  // eslint-disable-next-line
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<MyOrganisation>();
  const [orgaAndRoles, setOrgaAndRoles] = useState<OrgaAndRoles[]>([]);
  const navigate = useNavigate();

  const [catchYearEnabled, setCatchYearEnabled] = useState<boolean>(false);
  const [memberWorkEnabled, setMemberWorkEnabled] = useState<boolean>(false);
  const [memberWorkExtendedEnabled, setMemberWorkExtendedEnabled] =
    useState<boolean>(false);
  const [inspectionEnabled, setInspectionEnabled] = useState<boolean>(false);
  const [guestCardEnabled, setGuestCardEnabled] = useState<boolean>(false);
  const [catchReportsEnabled, setCatchReportsEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    const selectedOrga = getData(StorageKey.SELECTED_ORGANISATION);
    if (selectedOrga) {
      const orgaAndRoles = getData(StorageKey.ORGA_AND_ROLES);
      if (orgaAndRoles) {
        setOrgaAndRoles(JSON.parse(orgaAndRoles));
        setSelectedOrganisation(JSON.parse(selectedOrga));

        const fetchData = async () => {
          setCatchYearEnabled(
            await NetworkCalls.featureEnabled(Features.REPORT_CATCH_YEAR),
          );

          setMemberWorkEnabled(
            await NetworkCalls.featureEnabled(Features.MEMBER_WORK),
          );

          setMemberWorkExtendedEnabled(
            await NetworkCalls.featureEnabled(Features.MEMBER_WORK_EXTENDED),
          );

          setInspectionEnabled(
            await NetworkCalls.featureEnabled(Features.INSPECTION),
          );

          setGuestCardEnabled(
            await NetworkCalls.featureEnabled(Features.GUEST_CARD),
          );

          setCatchReportsEnabled(
            await NetworkCalls.featureEnabled(Features.REPORT_CATCHES),
          );
        };

        fetchData().catch(console.error);
      }
    }
  }, []);

  const options = orgaAndRoles.map((item) => {
    return (
      <option key={item.id} value={item.id}>
        {item.organisationName}
      </option>
    );
  });

  function getMyRolesAtSelectedOrganisation() {
    const rawSelectedOrga = getData(StorageKey.SELECTED_ORGANISATION);
    if (rawSelectedOrga) {
      const selectedOrganisation = JSON.parse(rawSelectedOrga);
      const result = orgaAndRoles.filter(
        (elem) => elem.id === selectedOrganisation.id,
      );
      if (result && result.length === 1) {
        return result[0].roles;
      }
    }
  }

  function mySelectedOrganisation(event: { target: { value: string } }) {
    const selectedOrganisation = orgaAndRoles.filter(
      (elem) => elem.id === event.target.value,
    );
    if (selectedOrganisation.length === 1) {
      storeData(
        StorageKey.SELECTED_ORGANISATION,
        JSON.stringify(selectedOrganisation[0]),
      );
      navigate("/dashboard");
    } else {
      // TODO show error
    }
  }

  function navigationAllgemein() {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#allgemein"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          role="button"
          aria-expanded={false}
        >
          <span className="nav-link-icon d-md-none d-lg-inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
              <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
              <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
            </svg>
          </span>
          <span className="nav-link-title">Allgemein</span>
        </a>
        <div className="dropdown-menu">
          <Link
            to="/dashboard/administration/member-list"
            className="dropdown-item"
          >
            Mitglieder
          </Link>
          <Link
            to="/dashboard/administration/member-list/upload"
            className="dropdown-item"
          >
            Mitgliederliste aktualisieren
          </Link>
          <Link
            to="/dashboard/administration/user/user-list"
            className="dropdown-item"
          >
            Benutzer
          </Link>
          {hasRole(["ROLE_ORGANISATION_ADMINISTRATOR"]) ? (
            <Link to="/dashboard/termine/verwalten" className="dropdown-item">
              Termine verwalten
            </Link>
          ) : (
            ""
          )}
          {hasRole(["ROLE_ORGANISATION_ADMINISTRATOR"]) ? (
            <Link to="/dashboard/news/verwalten" className="dropdown-item">
              Neuigkeiten verwalten
            </Link>
          ) : (
            ""
          )}
          {hasRole(["ROLE_ORGANISATION_ADMINISTRATOR"]) ? (
            <Link
              to="/dashboard/dokumentenverwaltung"
              className="dropdown-item"
            >
              Dokumente verwalten
            </Link>
          ) : (
            ""
          )}
        </div>
      </li>
    );
  }

  /*

         */

  function navigationGewaesserverwaltung() {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#allgemein"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          role="button"
          aria-expanded={false}
        >
          <span className="nav-link-icon d-md-none d-lg-inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
              <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
              <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
            </svg>
          </span>
          <span className="nav-link-title">Gewässerverwaltung</span>
        </a>
        <div className="dropdown-menu">
          <Link
            to="/dashboard/water-administration/water-list"
            className="dropdown-item"
          >
            Gewässerübersicht
          </Link>
        </div>
      </li>
    );
  }

  function navigationInspection() {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#inspection"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          role="button"
          aria-expanded="false"
        >
          <span className="nav-link-icon d-md-none d-lg-inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-list-search"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M15 15m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
              <path d="M18.5 18.5l2.5 2.5"></path>
              <path d="M4 6h16"></path>
              <path d="M4 12h4"></path>
              <path d="M4 18h4"></path>
            </svg>
          </span>
          <span className="nav-link-title">Fischereiaufsicht</span>
        </a>
        <div className="dropdown-menu" data-bs-popper="static">
          <Link to="inspections" className="dropdown-item">
            Getätigte Kontrollen
          </Link>
        </div>
      </li>
    );
  }

  function navigationGuestCards() {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#payments"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          role="button"
          aria-expanded="false"
        >
          <span className="nav-link-icon d-md-none d-lg-inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-list-search"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M15 15m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
              <path d="M18.5 18.5l2.5 2.5"></path>
              <path d="M4 6h16"></path>
              <path d="M4 12h4"></path>
              <path d="M4 18h4"></path>
            </svg>
          </span>
          <span className="nav-link-title">Gastkarten</span>
        </a>
        <div className="dropdown-menu" data-bs-popper="static">
          <Link
            to="/dashboard/administration/guest-cards"
            className="dropdown-item"
          >
            Gastkarten verwalten
          </Link>
          <Link to="guest-cards/payments" className="dropdown-item">
            Gekaufte Gastkarten
          </Link>
        </div>
      </li>
    );
  }

  function navigationCatchReport() {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#catch-reports"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          role="button"
          aria-expanded="true"
        >
          <span className="nav-link-icon d-md-none d-lg-inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-fish-hook"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M16 9v6a5 5 0 0 1 -10 0v-4l3 3"></path>
              <path d="M16 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
              <path d="M16 5v-2"></path>
            </svg>
          </span>
          <span className="nav-link-title">Fangmeldungen</span>
        </a>
        <div className="dropdown-menu" data-bs-popper="static">
          {catchReportsEnabled ? (
            <Link to="catch-report/list" className="dropdown-item">
              Fangmeldungen
            </Link>
          ) : (
            ""
          )}

          {catchYearEnabled ? (
            <Link to="catch-report/catch-year" className="dropdown-item">
              Fangjahr
            </Link>
          ) : (
            ""
          )}
        </div>
      </li>
    );
  }

  function navigationMemberWork() {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#member-work"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          role="button"
          aria-expanded="true"
        >
          <span className="nav-link-icon d-md-none d-lg-inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-shovel"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M17 4l3 3"></path>
              <path d="M18.5 5.5l-8 8"></path>
              <path d="M8.276 11.284l4.44 4.44a.968 .968 0 0 1 0 1.369l-2.704 2.704a4.108 4.108 0 0 1 -5.809 -5.81l2.704 -2.703a.968 .968 0 0 1 1.37 0z"></path>
            </svg>
          </span>
          <span className="nav-link-title">Arbeitsdienst</span>
        </a>
        <div className="dropdown-menu" data-bs-popper="static">
          <Link to="member-work" className="dropdown-item">
            Übersicht
          </Link>
        </div>
      </li>
    );
  }

  function navigationMemberWorkExtended() {
    return (
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle"
          to="#"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          role="button"
          aria-expanded="true"
        >
          <span className="nav-link-icon d-md-none d-lg-inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-shovel"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M17 4l3 3"></path>
              <path d="M18.5 5.5l-8 8"></path>
              <path d="M8.276 11.284l4.44 4.44a.968 .968 0 0 1 0 1.369l-2.704 2.704a4.108 4.108 0 0 1 -5.809 -5.81l2.704 -2.703a.968 .968 0 0 1 1.37 0z"></path>
            </svg>
          </span>
          <span className="nav-link-title">Arbeitsdienst</span>
        </Link>
        <div className="dropdown-menu" data-bs-popper="static">
          <Link to="member-work-extended" className="dropdown-item">
            Übersicht
          </Link>
        </div>
      </li>
    );
  }

  const navigationReports = () => {
    return (
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle"
          to="#"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          role="button"
          aria-expanded="true"
        >
          <span className="nav-link-icon d-md-none d-lg-inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-chart-pie"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-6.8a2 2 0 0 1 -2 -2v-7a.9 .9 0 0 0 -1 -.8" />
              <path d="M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a1 1 0 0 1 -1 -1v-4.5" />
            </svg>
          </span>
          <span className="nav-link-title">Auswertungen</span>
        </Link>
        <div className="dropdown-menu" data-bs-popper="static">
          {memberWorkExtendedEnabled && (
            <Link to="member-work-report" className="dropdown-item">
              Arbeitsdienst
            </Link>
          )}
          {catchReportsEnabled && (
            <Link to="catch-reports" className="dropdown-item">
              Fangmeldungen
            </Link>
          )}
        </div>
      </li>
    );
  };

  function navigationAdmin() {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#admin"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          role="button"
          aria-expanded="true"
        >
          <span className="nav-link-icon d-md-none d-lg-inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-fish-hook"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M16 9v6a5 5 0 0 1 -10 0v-4l3 3"></path>
              <path d="M16 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
              <path d="M16 5v-2"></path>
            </svg>
          </span>
          <span className="nav-link-title">Administration</span>
        </a>
        <div className="dropdown-menu" data-bs-popper="static">
          <Link
            to="administration-area/feature-configuration"
            className="dropdown-item"
          >
            Featurezugänge
          </Link>
          <Link
            to="administration-area/organisation-categories"
            className="dropdown-item"
          >
            Kategorien
          </Link>
        </div>
      </li>
    );
  }

  function hasRole(roleList: string[]) {
    if (roleList && roleList.length > 0) {
      for (let i = 0; i < roleList.length; i++) {
        const myRolesAtSelectedOrga = getMyRolesAtSelectedOrganisation();
        if (
          myRolesAtSelectedOrga &&
          myRolesAtSelectedOrga.includes(roleList[i])
        ) {
          return true;
        }
      }
    }
    return false;
  }

  function getNavigation() {
    return (
      <div className="page">
        <header className="navbar navbar-expand-md navbar-light d-print-none">
          <div className="container-xl">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-menu"
              aria-controls="navbar-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
              Fangkarte
            </h1>
            <div className="navbar-nav flex-row order-md-last">
              {orgaAndRoles.length > 0 ? (
                <div className="nav-item d-none d-md-flex me-3">
                  <select
                    className="form-select"
                    id="organisation"
                    value={selectedOrganisation?.id}
                    onChange={mySelectedOrganisation}
                  >
                    {orgaAndRoles.length > 0 ? options : ""}
                  </select>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </header>
        <header className="navbar-expand-md">
          <div className="collapse navbar-collapse" id="navbar-menu">
            <div className="navbar navbar-light">
              <div className="container-xl">
                <ul className="navbar-nav">
                  {hasRole([
                    "ROLE_ORGANISATION_ADMINISTRATOR",
                    "ROLE_ORGANISATION_MEMBER_LIST",
                  ])
                    ? navigationAllgemein()
                    : ""}
                  {hasRole(["ROLE_ORGANISATION_ADMINISTRATOR"])
                    ? navigationGewaesserverwaltung()
                    : ""}
                  {hasRole([
                    "ROLE_ORGANISATION_ADMINISTRATOR",
                    "ROLE_ORGANISATION_INSPECTOR_ADMINISTRATOR",
                  ]) && inspectionEnabled
                    ? navigationInspection()
                    : ""}
                  {hasRole([
                    "ROLE_ORGANISATION_ADMINISTRATOR",
                    "ROLE_ORGANISATION_CATCHREPORT_ADMINISTRATOR",
                  ]) &&
                  (catchYearEnabled || catchReportsEnabled)
                    ? navigationCatchReport()
                    : ""}
                  {hasRole([
                    "ROLE_ORGANISATION_CATCHREPORT_ADMINISTRATOR",
                    "ROLE_ORGANISATION_ADMINISTRATOR",
                  ]) && memberWorkEnabled
                    ? navigationMemberWork()
                    : ""}
                  {hasRole([
                    "ROLE_ORGANISATION_CATCHREPORT_ADMINISTRATOR",
                    "ROLE_ORGANISATION_ADMINISTRATOR",
                  ]) && memberWorkExtendedEnabled
                    ? navigationMemberWorkExtended()
                    : ""}
                  {hasRole([
                    "ROLE_ORGANISATION_CATCHREPORT_ADMINISTRATOR",
                    "ROLE_ORGANISATION_ADMINISTRATOR",
                    "ROLE_ORGANISATION_INSPECTOR_ADMINISTRATOR",
                  ])
                    ? navigationReports()
                    : ""}
                  {hasRole([
                    "ROLE_ORGANISATION_ADMINISTRATOR",
                    "ROLE_ORGANISATION_GUESTCARD_ADMINISTRATOR",
                  ]) && guestCardEnabled
                    ? navigationGuestCards()
                    : ""}
                  {hasRole(["ROLE_ADMIN"]) ? navigationAdmin() : ""}
                </ul>
              </div>
            </div>
          </div>
        </header>
        <div id="detail" className="page-wrapper">
          <Outlet />
        </div>
      </div>
    );
  }

  return <div>{orgaAndRoles.length > 0 ? getNavigation() : ""}</div>;
}
