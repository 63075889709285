import { ChangeEvent, useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import { MyOrganisation } from "../../dtos/MyOrganisation";
import { getOrganisation } from "../../service/LoadStorageData";
import Select, { MultiValue } from "react-select";
import { WaterAggregationDTO } from "../../components/charts/model/WaterAggregationDTO";
import { AmountLengthChart } from "../../components/charts/AmountLengthChart";

interface SelectOptions {
  value: string;
  label: string;
}

export const CatchReportCharts = () => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [orgaFishes, setOrgaFishes] = useState<SelectOptions[]>([]);
  const [selectedOrgaFishes, setSelectedOrgaFishes] = useState<SelectOptions[]>(
    [],
  );
  const [waterList, setWaterList] = useState<SelectOptions[]>([]);
  const [selectedWaterList, setSelectedWaterList] = useState<SelectOptions[]>(
    [],
  );

  const [chartData, setChartData] = useState<WaterAggregationDTO[]>([]);

  useEffect(() => {
    const fetchOptions = async () => {
      let organisation: MyOrganisation = getOrganisation();
      const data = await NetworkCalls.getOrganisationFishes(organisation.id);
      setOrgaFishes(
        data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((fish) => ({
            value: fish.fishId,
            label: fish.name,
          })),
      );
      const waterList = await NetworkCalls.getOrganisationWaters(
        organisation.id,
      );
      setWaterList(
        waterList
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((water) => ({ value: water.id, label: water.name })),
      );
    };
    fetchOptions().then();
  }, []);

  const onSubmit = async () => {
    let data: WaterAggregationDTO[] = [];
    for (const selectedFish of selectedOrgaFishes) {
      for (const selectedWater of selectedWaterList) {
        const result = await NetworkCalls.aggregatedAmountLengthFishData(
          convertToISODateTime(startDate),
          convertToISODateTime(endDate),
          selectedFish.label,
          selectedWater.label,
        );

        data.push(result);
      }
    }

    setChartData(data);
  };

  const convertToISODateTime = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toISOString();
  };

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value; // Access the value directly
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value; // Access the value directly
    setEndDate(selectedDate);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-3 bg-light border-end"
          style={{ minHeight: "100vh" }}
        >
          <h5 className="p-3">Filter</h5>
          <form className="p-3">
            <div className="mb-3">
              <label htmlFor="filterDropdown" className="form-label">
                Fischart
              </label>
              <Select
                options={orgaFishes}
                isMulti
                onChange={(elem: MultiValue<SelectOptions>) => {
                  setSelectedOrgaFishes([...elem]);
                }}
                placeholder="Fischart auswählen"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="filterDropdown" className="form-label">
                Gewässer
              </label>
              <Select
                options={waterList}
                isMulti
                onChange={(elem: MultiValue<SelectOptions>) => {
                  setSelectedWaterList([...elem]);
                }}
                placeholder="Gewässerauswahl"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="startDate" className="form-label">
                Von
              </label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={handleDateChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={"endDate"} className="form-label">
                Bis
              </label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                name="endDate"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={onSubmit}
                disabled={
                  selectedWaterList.length === 0 ||
                  selectedOrgaFishes.length === 0 ||
                  startDate.length === 0 ||
                  endDate.length === 0
                }
              >
                Aktualisieren
              </button>
            </div>
          </form>
        </div>

        <div className="col-md-9">
          <div className="p-3">
            <h5>Auswertungen</h5>
            <div className="row">
              {chartData.map((chartData) => {
                if (chartData && chartData.fishAggregations.length > 0) {
                  return (
                    <div className="col-md-6 mb-6">
                      <AmountLengthChart aggregation={chartData} />
                    </div>
                  );
                } else {
                  return <>Keine Daten vorhanden</>;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
