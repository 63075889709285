import {getBearerToken} from "../service/LoadStorageData";
import axios from "axios";
import React from "react";

export const DownloadExcelCsvButton = ({downloadUrl}: { downloadUrl: string }) => {
    return (
        <p
            className="link-secondary"
            title="Download"
            data-bs-placement="top"
            data-bs-toggle="tooltip"
            onClick={async (event) => {
                event.preventDefault();
                try {
                    let token = getBearerToken();
                    const response = await axios.get(downloadUrl, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        responseType: "blob", // Ensures the response is treated as a binary Blob
                    });

                    // Extract filename from Content-Disposition header
                    const contentDisposition =
                        response.headers["content-disposition"];
                    let filename = "filename";
                    if (contentDisposition) {
                        const match =
                            contentDisposition.match(/filename="(.+?)"/);
                        if (match && match[1]) {
                            filename = match[1];
                        }
                    }

                    // Create a URL for the blob
                    const blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });
                    const downloadLink = document.createElement("a");
                    const url = window.URL.createObjectURL(blob);

                    // Configure the download link
                    downloadLink.href = url;
                    downloadLink.download = filename; // Use the extracted filename
                    document.body.appendChild(downloadLink);

                    // Trigger the download and clean up
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    console.error("Download failed:", error);
                    alert("An error occurred while downloading the file.");
                }
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-cloud-download"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                <path d="M12 13l0 9"></path>
                <path d="M9 19l3 3l3 -3"></path>
            </svg>
        </p>
    );
}