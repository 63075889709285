import React, {useEffect, useState} from "react";
import {NetworkCalls} from "../../NetworkCalls";
import uuid from "react-uuid";
import ReportMemberWorkDTO from "../../dtos/ReportMemberWorkDTO";
import axios from "axios";
import {OrgaAndRoles} from "../../models/OrgaAndRoles";
import {getData} from "../../utils/storage/Storage";
import {StorageKey} from "../../utils/storage/Keys";
import DateFormat from "../../utils/DateFormat";
import {DownloadExcelCsvButton} from "../../components/DownloadExcelCsvButton";

export default function MemberWorkReport() {
    const [selectedYear, setSelectedYear] = useState<number>(-1);
    const [years, setYears] = useState<number[]>([]);
    const [reportMemberWork, setReportMemberWork] = useState<
        ReportMemberWorkDTO[]
    >([]);

    useEffect(() => {
        if (selectedYear !== -1) {
            const fetchData = async () => {
                const data = await NetworkCalls.getMemberWorkReport(selectedYear);
                setReportMemberWork(data);
            };
            fetchData().catch(console.error);
        }

        if (years.length === 0) {
            const fetchData = async () => {
                const years = await NetworkCalls.getMemberWorkReportYears();
                setYears(years);

                if (years.length === 1) {
                    setSelectedYear(years[0]);
                }
            };
            fetchData().catch(console.error);
        }
    }, [selectedYear, years]);

    const options = years.map((year) => {
        return (
            <option key={year} value={year}>
                {year}
            </option>
        );
    });

    const ShowContent = () => {
        let selectedOrganisation: OrgaAndRoles = JSON.parse(
            getData(StorageKey.SELECTED_ORGANISATION) as string
        );
        return (
            <div className="m-5 col row justify-content-center">
                <div className="card border-0">
                    <div className="card-header">
                        <div className="row" style={{minWidth: "100%"}}>
                            <div className="col-1">
                                <div className="form-inline">
                                    <select
                                        id="yearControl"
                                        title="Jahr"
                                        className="form-control"
                                        onChange={(elem) =>
                                            setSelectedYear(Number(elem.target.value))
                                        }
                                        defaultValue={selectedYear}
                                    >
                                        {options}
                                    </select>
                                </div>
                            </div>
                            <div className="col-5">
                                <h4>Download</h4>
                                <DownloadExcelCsvButton
                                    downloadUrl={`${axios.defaults.baseURL}/reports/member-work/${selectedOrganisation.id}/${selectedYear}/export-csv`}/>
                            </div>
                        </div>
                    </div>
                    {showMemberWorkReportTable()}
                </div>
            </div>
        );
    };

    function showMemberWorkReportTable() {
        return (
            <table className="table table-hover">
                <thead>
                <tr key={uuid()}>
                    <th scope="col">Mitgliedsnummer</th>
                    <th scope="col">Vorname</th>
                    <th scope="col">Nachname</th>
                    <th scope="col">AD gemacht</th>
                    <th scope="col">Arbeitsdienst</th>
                    <th scope="col">wann</th>
                </tr>
                </thead>
                <tbody id="table-content">{createRows()}</tbody>
            </table>
        );
    }

    function createRows() {
        return reportMemberWork.map((elem) => {
            if (elem.memberWork) {
                return elem.memberWork.map((mw) => {
                    return (
                        <tr key={uuid()} className="user-select-auto">
                            <td>{elem.memberNumber}</td>
                            <td> {elem.firstName}</td>
                            <td> {elem.lastName}</td>
                            <td>{memberWork(true)}</td>
                            <td>{mw.title}</td>
                            <td>{DateFormat.toDateTimeReadableFormat(mw.start)}</td>
                        </tr>
                    );
                });
            } else {
                return (
                    <tr key={uuid()} className="user-select-auto">
                        <td>{elem.memberNumber}</td>
                        <td> {elem.firstName}</td>
                        <td> {elem.lastName}</td>
                        <td>{memberWork(false)}</td>
                        <td></td>
                        <td></td>
                    </tr>
                );
            }
        });
    }

    const memberWork = (memberWork: boolean | undefined) => {
        if (memberWork) {
            return <span className="status status-green">Ja</span>;
        } else {
            return <span className="status status-red">Nein</span>;
        }
    };

    return <ShowContent/>;
}
